<template>
	<div class="page-container">
		<nav-bar title="远程教育在线报名" :fixed="true" id="navbar"></nav-bar>
		<!-- 报名表单 -->
		<div class="form-area" v-if="!isSignupSuccess && !isSignupConfirm">
			<van-notice-bar color="#FF0000" background="#ecf9ff" left-icon="info-o">
				请您如实填写以下报名信息
			</van-notice-bar>

			<van-form @submit="onConfirm" ref="formData" action="">
				<div class="title" v-if="form.agentId > 0">
					<span style="color: #888;">代理编号：{{form.agentId}}</span>
				</div>
				<van-field
					v-model="form.studentName"
					type="input"
					name="studentName"
					center
					clearable
					label="学员姓名"
					placeholder="请输入真实姓名"
					maxlength="20"
					:rules="[{ required: true, message: '请填写真实姓名' }]"
				>
				</van-field>
				<van-field
					v-model="form.certNum"
					type="input"
					name="certNum"
					center
					clearable
					label="身份证号"
					placeholder="请输入身份证号码"
					maxlength="18"
					:rules="[{ required: true, pattern: idCardPattern, message: '请输入正确的证件号' }]"
				/>				
				<div >
					<!-- 性别 -->
					<van-field name="sex" label="性别" v-if="canBeSearch">
						<template #input>
							<van-radio-group v-model="form.sex" direction="horizontal">
								<van-radio name="男">男</van-radio>
								<van-radio name="女">女</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 手机号码 -->
					<van-field
						v-model="form.mobile"
						name="moblie"
						type="tel"
						label="手机号码"
						placeholder="手机号码"
						:rules="[{ required: true, pattern: mobilePattern, message: '请输入正确的手机号' }]"
						maxlength="11"
					/>
					<van-field
						v-model="fieldValue"
						name="fieldValue"
						is-link
						readonly
						label="可选课程"
						placeholder="请选择报名培训课程"
						@click="show = true"
					/>
					<van-popup v-model="show" round position="bottom">
						<van-cascader
							v-model="cascaderValue"
							title="请选择报名培训课程"
							:options="options"
							@close="show = false"
							@finish="onFinish"
						/>
					</van-popup>
					<van-cell
						title="课程名称"
						:value="planName"
						label="请您仔细确认课程名称无误"
					/>
					<div style="margin: 16px">
						<van-button round block type="info" native-type="submit">下一步
						</van-button>
					</div>
				</div>
			</van-form>
		</div>
		<!-- 报名信息确认 -->
		<div class="confirm-area" v-if="isSignupConfirm">
			<div class="checked-plan">
				<van-cell-group title="请确认您的报名信息" inset>
					<van-cell title="真实姓名" :value="form.studentName" />
					<van-cell title="身份证号" :value="form.certNum" />
					<van-cell title="性别" :value="form.sex" v-if="form.sex" />
					<van-cell title="手机号码" :value="form.mobile" />
					<van-cell title="报名课程" :value="fieldValue" />
					<div style="margin: 16px; text-align: center">
						<van-button round plain type="info" native-type="button" @click="onModify"> 修改 </van-button>&nbsp;
						<van-button round type="info" native-type="button" @click="onSubmit"> 确认提交 </van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- 报名成功 -->
		<div class="checked-area" v-if="isSignupSuccess">
			<van-icon name="checked" color="#67C23A" size="80"/>
			<div><span class="checked-msg">恭喜您，报名成功！</span></div>
			<div class="checked-plan">
				<van-cell-group title="报名信息" inset>
					<van-cell title="真实姓名" :value="form.studentName" />
					<van-cell title="身份证号" :value="form.certNum" />
					<van-cell title="性别" :value="form.sex" v-if="form.sex" />
					<van-cell title="手机号码" :value="form.mobile" />
					<van-cell title="报名课程" :value="fieldValue" />
					<div style="margin: 16px">
						<van-button
							round
							block
							type="info"
							native-type="button"
							@click="gotoStudy"
						>点击进入在线教育平台，开始学习吧！
						</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '提交中···' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {NavBar, Toast} from "vant";
	import {getStudyPlanInfoById,whCertificateSignUp} from "../../api/user";
	import { wipeBase64Rrefix } from "../../utils";

    export default {
        name: "wuhai-certificate1",
		components: {NavBar},
		data() {
			return {
				title: '',
				canBeSearch: true,
				showLoading: false,
				isSignupSuccess: false,
				isSignupConfirm: false,
				searchResult: false,
				showPicker: false,
				showPickerNd: false,
				showPickerDq: false,
				showPickerSy: false,
				form: {
					certNum: '',
					studentName: '',
					agentId: 0,
					studentPic: '',
				},
				bookCount: 0,
				bookList: [],
				show: false,
				fieldValue: "",
				mobilePattern: /^1[0-9]{10}$/,
				idCardPattern: /^(([1-9][0-9]{5}(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}([0-9]|X|x))|([1-9][0-9]{5}[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}))|^([A-Z]\d{8}(\(\w{1}\))?)$/,
				cascaderValue: "",
				categroy: "",
				pCategory: "",
				planName: "",
				options: [{
						text: "资格证超期恢复",
						value: "115",
						children: [
							{ text: "客运", value: "29" },
							{ text: "货运", value: "28" },
							{ text: "出租车（36学时）", value: "72" },
							{ text: "危险品", value: "24" },
						],}],
				photoList: [],
			}
		},
		mounted() {
			const {agent} = this.$route.query;
			if(typeof (agent) !== 'undefined') {
				this.form.agentId = agent;
			}
		},
		methods: {
			afterRead(file) {
				//this.photoList.push({'url': file.content, isImage: true});
				this.form.studentPic = wipeBase64Rrefix(file.content);
			},
        	reset() {
        		this.columns = [];
        		this.bookCount = 0;
        		this.bookList = [];
			},
			getPlanInfo(planId) {
				getStudyPlanInfoById(planId)
					.then((res) => {
						this.planName = res.planName;
					})
					.catch(() => {});
			},
			onConfirm() {
				if(typeof(this.form.studentName) === 'undefined' || this.form.studentName === '' || this.form.studentName == null) {
					return;
				}
				if(typeof(this.form.certNum) === 'undefined' || this.form.certNum === '' || this.form.certNum == null) {
					return;
				}
        if (!this.form.mobile) {
          Toast.fail("请您填写报名手机号码");
          return;
        }
        if (this.fieldValue === "") {
          Toast.fail("请您选择报名课程");
          return;
        }
        this.isSignupConfirm = true;
			},
			onModify() {
				this.isSignupConfirm = false;
			},
			onSubmit() {
				this.showLoading = true;
				this.form.planId = this.categroy;
				whCertificateSignUp(this.form)
					.then(() => {
						this.isSignupSuccess = true;
						this.isSignupConfirm = false;
						this.showLoading = false;
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			onConfirmPicker(value) {
				for (let i = 0; i < this.bookList.length; i++) {
					if(this.bookList[i].id_ECERT === value.value) {
						this.form.cyzglb = value.text;
						this.form.cyzglb_value = value.value;
						break;
					}
				}
				this.showPicker = false;
			},
			onConfirmPickerNd(value) {
				this.form.pxnd = value.text;
				this.form.pxnd_value = value.value;
				this.showPickerNd = false;
			},
			onConfirmPickerDq(value) {
				this.form.szdq = value.text;
				this.form.szdq_value = value.value;
				this.form.sydq = value.text;
				this.form.sydq_value = value.value;
				if(value.value === '150300') {
					this.canBeSearch = true;
					this.searchResult = false;
					this.loadStudyPlan(value.value);
				} else if(value.value === '152900') {
					this.canBeSearch = true;
					this.loadStudyPlan(value.value);
					this.searchResult = false;
				}
				this.showPickerDq = false;
			},
			onConfirmPickerSy(value) {
				this.form.sydq = value.text;
				this.form.sydq_value = value.value;
				this.showPickerSy = false;
			},
			gotoStudy() {
				let categoryId = this.pCategory;
				if (categoryId === 115) {
					//继续教育
					window.location.href = "/index.html#/?appNo=APP02";
				} else if (categoryId === 136) {
					// 计时教育
					window.location.href = "/index.html#/?appNo=APP05";
				} else {
					// 默认
					window.location.href = "/index.html#/?appNo=APP02";
				}
			},
	
			loadStudyPlan(cityCode) {
        		if(cityCode === '150300') {
					this.$set(this, 'options', this.options_wh);
				} else if(cityCode === '152900') {
					this.$set(this, 'options', this.options_als);
				}
			},
			onFinish({ selectedOptions }) {
				this.pCategory = selectedOptions[0].value;
				this.categroy = selectedOptions[selectedOptions.length - 1].value;
				this.show = false;
				this.fieldValue = selectedOptions.map((option) => option.text).join(" / ");
				this.getPlanInfo(this.categroy);
			},
		}
    }
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}

	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}

	.form-area {
		margin-top: 46px;
	}

	.title {
		margin: 0;
		padding: 16px 16px 16px;
		color: rgba(69, 90, 100, 0.6);
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}

	.checked-area {
		margin-top: 90px;
		text-align: center;
	}

	.confirm-area {
		text-align: center;
	}

	.checked-msg {
		font-size: 14px;
		color: #67c23a;
	}

	.checked-plan {
		text-align: left;
		margin-top: 40px;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		width: 120px;
		height: 120px;
		border-radius: 15px;
		background-color: #fff;
		padding-top: 32px;
	}
</style>
